'use client';
import { QuestionDisplay } from '@/components/ddq/components/question-display';
import { useDDQState } from '@/components/ddq/use-ddq-state';
import { Separator } from '@/components/ui/separator';
import { TooltipProvider } from '@/components/ui/tooltip';
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup
} from '@/components/ui/resizable';

import { QuestionList } from './question-list';

import cookie from 'js-cookie';

import { useBlankStatus } from '@/lib/is-blank';
import { useExportMixin } from '@/components/ddq/export';
import { AnalysisResponse } from '@/types';
import { useEffect } from 'react';
import { AssistantPanel } from './assistant-panel';

interface DDQRenderProps {
  analysisResponse: AnalysisResponse
}

export function DDQRender(props: DDQRenderProps) {
  const { state, pairObj, setDDQAnalysis } = useDDQState();

  const [ddqState] = state;

  useEffect(() => {
    setDDQAnalysis(props.analysisResponse)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.analysisResponse])

  const { analysisResponse: analysis } = ddqState;

  const { isExportEnabled, ExportButton } = useExportMixin(
    analysis || undefined
  );

  const isDraftDocument = !analysis?.approved_date;

  const { isBlank } = useBlankStatus();

  if (!analysis) return <></>;

  const layoutCookie = cookie.get('ddq-render-resizable-panels:layout-2');
  const defaultLayout = layoutCookie ? JSON.parse(layoutCookie) : [15, 30, 55];

  const allItems = analysis.similar_pairs;

  return (
    <TooltipProvider delayDuration={0}>
      <ResizablePanelGroup
        direction='horizontal'
        onLayout={(sizes: number[]) => {
          cookie.set(
            'ddq-render-resizable-panels:layout-2',
            JSON.stringify(sizes)
          );
        }}
        className='max-h-full items-stretch'
      >
        <ResizablePanel
          className='h-full'
          defaultSize={defaultLayout[0]}
          minSize={15}
        >
          <div className='h-full flex flex-col'>
            <div className='flex items-center px-4 py-2'>
              <div className='flex items-center gap-2 justify-between w-full'>
                <h1 className='text-xl font-bold'>Questionnaire</h1>
                <div className='flex shrink-0 gap-2'>
                  {isDraftDocument && isExportEnabled && !isBlank && (
                    <ExportButton />
                  )}
                </div>
              </div>
            </div>
            <Separator />
            <div className='max-h-full flex flex-col flex-grow overflow-y-hidden mt-0'>
              <QuestionList showStatus={!isBlank} items={allItems} />
            </div>
          </div>
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel defaultSize={defaultLayout[1]} minSize={30}>
          <QuestionDisplay key={pairObj && pairObj[0].id} />
        </ResizablePanel>
        <ResizableHandle withHandle />
        {!isBlank && (
          <ResizablePanel defaultSize={defaultLayout[2]} minSize={30}>
            {pairObj && (
              <AssistantPanel key={pairObj[0].id} selectedPair={pairObj} />
            )}
          </ResizablePanel>
        )}
      </ResizablePanelGroup>
    </TooltipProvider>
  );
}
