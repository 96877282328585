import { useState } from 'react';
import { SearchDialog } from './components/search-dialog';
import { useSearchState } from './use-search-state';
import { SearchInput, SearchButton } from './components/search-input';
import { ignoreWhenFocused, useKeyboardShortcut } from '@/lib/use-keyboard-shortcut';

interface SearchBarProps {
  onSearchEvent?: (query: string) => void;
  searchLabel?: string;
  inDDQContext?: boolean;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearchEvent, searchLabel = 'Search the database...', inDDQContext = false }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { searchQuery, addSearchQuery } = useSearchState();

  const handleSearch = () => {
    if (searchQuery.length === 0) return;

    addSearchQuery(searchQuery);

    // Optionally emit a search event
    if (onSearchEvent) {
      onSearchEvent(searchQuery);
    }

    setIsDialogOpen(true);
  };

  const handleOpenDialog = () => {
    setTimeout(() => setIsDialogOpen(true), 1);
  };

  const handleSearchLaunch = ignoreWhenFocused((event: KeyboardEvent) => {
    console.log('search launch', event)

    if ((event.key === 's' || event.key === 'ß') && event.altKey) {
      handleOpenDialog();
      return true;
    }
  });

  useKeyboardShortcut(handleSearchLaunch, 5);

  return (
    <div className='flex flex-1 items-center mx-20'>
      <SearchInput 
        onSearch={handleSearch} 
        onClickInput={handleOpenDialog} 
        placeholder={searchLabel} 
      />
      <SearchButton onSearch={handleSearch} onClickButton={handleOpenDialog} showShortcut />
      <SearchDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        searchLabel={searchLabel}
        inDDQContext={inDDQContext}
      />
    </div>
  );
};

export default SearchBar;
