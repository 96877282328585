import React from 'react';
import { useCategoryQuery } from '@/queries/category';
import { Badge } from '../ui/badge';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '../ui/tooltip';
import { TooltipArrow } from '@radix-ui/react-tooltip';

interface CategoryTagProps {
  categoryId: string | null;
  contextCategoryId?: string;
}

const CategoryTag: React.FC<CategoryTagProps> = ({
  categoryId,
  contextCategoryId
}) => {
  const { categoryQuery } = useCategoryQuery();
  const category = categoryQuery.data?.find((cat) => cat.id === categoryId);
  // const contextCategory = contextCategoryId ? categoryQuery.data?.find(cat => cat.id === contextCategoryId) : null;

  const isContextMatch = contextCategoryId && contextCategoryId === categoryId;

  let show = false;
  let tagStyle = 'bg-gray-500';
  let tooltip = '';

  if (isContextMatch) {
    show = true;
    tagStyle = 'bg-green-500';
    tooltip = 'Same category as the current DDQ.';
  } else if (contextCategoryId && !isContextMatch) {
    show = true;
    tagStyle = 'bg-yellow-500';
    tooltip = 'Different category from the current DDQ.';
  } else if (!category) {
    show = true;
    tagStyle = 'bg-gray-500';
    tooltip = 'Content without a category.';
  } else if (!contextCategoryId) {
    show = true;
    tagStyle = 'bg-gray-500';
    tooltip = ""
  }

  tagStyle = 'bg-gray-500'

  if (!show) return null;

  return (
    tooltip ? (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <Badge className={`${tagStyle}`}>{category?.name || 'Uncategorized'}</Badge>
          </TooltipTrigger>
          <TooltipContent side='right'>
            <TooltipArrow />
            <p>{tooltip}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    ) : (
      <Badge className={`${tagStyle}`}>{category?.name || 'Uncategorized'}</Badge>
    )
  );
};

export default CategoryTag;
