// useKeyboardShortcut.ts
import { useContext, useEffect, useRef } from 'react';
import { KeyboardManagerContext, KeyboardShortcutHandler } from './keyboard-manager';

export function useKeyboardShortcut(callback: KeyboardShortcutHandler['callback'], priority = 0) {
  const context = useContext(KeyboardManagerContext);

  if (!context) {
    throw new Error('useKeyboardShortcut must be used within a KeyboardManagerProvider');
  }

  const { registerHandler, unregisterHandler } = context;
  const callbackRef = useRef(callback);

  // Update ref if callback changes
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler: KeyboardShortcutHandler = {
      callback: (event: KeyboardEvent) => callbackRef.current(event),
      priority
    };
    registerHandler(handler);
    return () => {
      unregisterHandler(handler);
    };
  }, [priority, registerHandler, unregisterHandler]);
}

export function ignoreWhenFocused(callback: (event: KeyboardEvent) => boolean | void) {
  return (event: KeyboardEvent) => {
    const activeElement = document.activeElement as Element & HTMLElement;
    const isContentEditable =
      activeElement &&
      (activeElement.tagName === 'INPUT' ||
        activeElement.tagName === 'TEXTAREA' ||
        activeElement.isContentEditable);

    if (!isContentEditable) {
      return callback(event);
    }
  };
}
