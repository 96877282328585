import { forwardRef } from 'react';
import HighlightCopy, { HighlightCopyHandle } from '@/components/generic/highlight-copy';
import { ShortcutKey } from '../minimal-tiptap/components/shortcut-key';

export const CopyCitation = forwardRef<HighlightCopyHandle, {
  friendlyName: string;
  approvedDate: string;
}>(({ friendlyName, approvedDate }, ref) => {
  return (
    <HighlightCopy ref={ref} hiddenChildren copyTitle='Copy Citation' iconSlot={<ShortcutKey keys={['alt', 't']} />}>
      <div>
        <p>Taken from: {friendlyName}</p>
        <p>Approved on: {approvedDate}</p>
      </div>
    </HighlightCopy>
  );
});

CopyCitation.displayName = 'CopyCitation';
