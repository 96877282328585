import { Workbox } from 'workbox-window';

import workerUrl from '../sw.js?worker&url';
const baseUrl = window.location.origin;

const DEV = import.meta.env.DEV;
const STAGING = import.meta.env.MODE === 'staging';

let wb: Workbox | undefined = undefined;

if ('serviceWorker' in navigator) {
  const url = new URL(workerUrl, baseUrl);
  url.searchParams.delete('type');
  if (DEV) url.searchParams.append('dev', '1');
  if (STAGING) url.searchParams.append('stg', '1');

  const registeredUrl = url.toString();
  console.log('registered workerUrl:', registeredUrl);

  wb = new Workbox(registeredUrl);

  (async () => {
    await wb.register();

    wb.messageSkipWaiting();

    await Promise.race([wb.active, wb.controlling]);

    console.log('asking version');

    const response = await wb.messageSW({ type: 'GET_VERSION' });

    console.log('sw version response', response);

    setInterval(async () => {
      if (!wb) return;

      const response = await wb.messageSW({ type: 'KEEP_ALIVE' });

      console.log('sw keep alive response', response);
    }, 2000);
  })();
}

export const setAuthToken = async (token?: string | null) => {
  if (!wb) return;

  const response = await wb.messageSW({
    type: 'TOKEN',
    token: token
  });

  console.log('sw token response', response);
};
