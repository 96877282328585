import { cn } from '@/lib/utils';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useDDQState } from '../use-ddq-state';
import { AnalysisResponse } from '@/types';

import { Markdown } from '@/components/generic/markdown';

import '@/css/list.css';
import { captureEvent } from '@/lib/analytics';
import { Badge } from '@/components/ui/badge';
import { Loader2, SearchIcon } from 'lucide-react';

import { useEffect, useRef, useState } from 'react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { useProgressiveSearch } from '../progressive-search';

interface QuestionListProps {
  items: AnalysisResponse['similar_pairs'];
  showStatus?: boolean;
}

export function QuestionList({ items, showStatus = true }: QuestionListProps) {
  const { state: [ddqState], setSelectedQuestion } = useDDQState()
  const [userClicks, setUserClicks] = useState<string[]>([]);

  const scrollAreaRef = useRef<HTMLDivElement>(null);

  const { results } = useProgressiveSearch();

  useEffect(() => {
    if (userClicks[userClicks.length - 1] === ddqState.selectedQuestion) return;

    if (scrollAreaRef.current) {
      const selectedElement = scrollAreaRef.current.querySelector(
        `[data-id="${ddqState.selectedQuestion}"]`
      );
      if (selectedElement) {
        selectedElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [ddqState.selectedQuestion, userClicks]);

  return (
    <ScrollArea
      ref={scrollAreaRef}
      className='flex-grow pt-4 scroll-area-no-table'
    >
      <div className='flex flex-col gap-2 p-4 pt-0'>
        {items.map(([pair], i) => (
          <button
            data-id={pair.id}
            key={pair.id}
            className={cn(
              'flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent',
              ddqState.selectedQuestion === pair.id && 'bg-muted border-black'
            )}
            onClick={() => {
              captureEvent('analysisClickQuestionCard', {
                doc_id: pair.ddq_id,
                pair_id: pair.id,
                index: i
              });

              setUserClicks([...userClicks, pair.id]);

              setSelectedQuestion(pair.id);
            }}
          >
            <div className='flex w-full gap-1'>
              <div>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div>
                        <Badge variant='outline'>{i + 1}</Badge>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent side='right'>
                      <p>The question number in this document.</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
              {showStatus &&
                results &&
                Object.keys(results || {}).length > 0 && (
                  <div className='flex items-center gap-2'>
                    {results[pair.id]?.processing && (
                      <Badge variant='outline'>
                        <Loader2 className={'h-4 w-4 animate-spin'}></Loader2>
                        &nbsp; Searching...
                      </Badge>
                    )}
                    {(!results[pair.id] ||
                      (!results[pair.id]?.processing &&
                        !results[pair.id]?.complete &&
                        results[pair.id]?.failed)) && (
                      <Badge variant='outline'>&nbsp; Queued Search...</Badge>
                    )}
                    {results[pair.id]?.complete && (
                      <Badge variant='outline'>
                        <SearchIcon className='w-4 h-4' />
                        &nbsp; Ready
                      </Badge>
                    )}
                  </div>
                )}
            </div>
            <div
              className='line-clamp-2 text-xs text-muted-foreground'
              title={pair.sanitized_content}
            >
              <Markdown
                className='font-normal max-h-16 overflow-hidden pl-2 border-l-2'
                content={pair.sanitized_content}
                ddqId={pair.ddq_id}
                disableProse
                enableImageFiltering
              />
            </div>
          </button>
        ))}
      </div>
    </ScrollArea>
  );
}
