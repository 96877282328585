import type { Editor } from '@tiptap/core'
import { ToolbarButton } from './toolbar-button'
import { ResetIcon } from '@radix-ui/react-icons';

export default function SectionSix({ editor }: { editor: Editor }) {
  return (
    <>
      {/* UNDO/REDO CONTROLS */}
      <ToolbarButton
        tooltip='Undo'
        aria-label='Undo'
        className='w-12'
        onClick={() => editor.chain().focus().undo().run()}
        disabled={!editor.can().undo()}
      >
        <ResetIcon className='size-5' />
      </ToolbarButton>
      <ToolbarButton
        tooltip='Redo'
        aria-label='Redo'
        className='w-12'
        onClick={() => editor.chain().focus().redo().run()}
        disabled={!editor.can().redo()}
      >
        <ResetIcon className='size-5 transform scale-x-[-1]' />
      </ToolbarButton>
    </>
  );
}
