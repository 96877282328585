import React, { useRef, useImperativeHandle, forwardRef } from 'react';
import { Button } from '../ui/button';

import { ClipboardCopyIcon } from '@radix-ui/react-icons';
import { captureEvent } from '@/lib/analytics';

export type HighlightCopyHandle = {
  copyText: () => void;
};

const HighlightCopy = forwardRef<HighlightCopyHandle, React.PropsWithChildren<{
  copyTitle?: string;
  hiddenChildren?: boolean;
  iconSlot?: React.ReactNode;
}>>(({
  children,
  copyTitle = 'Copy',
  hiddenChildren = false,
  iconSlot
}, ref) => {
  const textRef = useRef<HTMLDivElement | null>(null);

  const copyText = () => {
    captureEvent('copy', {
      copyTitle
    });

    const selection = window.getSelection();
    const range = document.createRange();

    if (textRef.current === null || selection === null)
      throw Error('No text ref to select');

    range.selectNodeContents(textRef.current);
    selection.removeAllRanges();
    selection.addRange(range);
    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      console.log('Copying text command was ' + msg);
    } catch (err) {
      console.log('Oops, unable to copy', err);
    }
    // Deselect text
    selection.removeAllRanges();
  };

  useImperativeHandle(ref, () => ({
    copyText
  }));

  return (
    <>
      <div
        className={
          hiddenChildren
            ? 'flex justify-end mt-1'
            : 'flex justify-end w-full z-50 h-3 px-2 mt-1'
        }
      >
        <Button className={'p-2 h-8'} variant={'ghost'} onClick={copyText}>
          {copyTitle}
          {iconSlot || <ClipboardCopyIcon className='ml-2' />}
        </Button>
      </div>
      <div
        className={hiddenChildren ? 'absolute' : ''}
        style={{ left: -100000, top: -100000 }}
        ref={textRef}
      >
        {children}
      </div>
    </>
  );
});

HighlightCopy.displayName = 'HighlightCopy';

export default HighlightCopy;
