import React from 'react';

interface AnswerColumnsProps {
  children: [React.ReactNode, React.ReactNode];
  className?: string;
  scrollLeftColumn?: boolean;
}

const AnswerColumns: React.FC<AnswerColumnsProps> = ({ children, className = '', scrollLeftColumn = false }) => {
  return (
    <div className={`flex gap-4 ${scrollLeftColumn ? 'h-full' : ''} ${className}`}>
      <div className={`flex-grow ${scrollLeftColumn ? 'overflow-y-scroll' : ''}`}>
        {children[0]}
      </div>
      <div className="flex-none">
        {children[1]}
      </div>
    </div>
  );
};

export default AnswerColumns;
