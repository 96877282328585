import posthog from 'posthog-js';

let initialized = false;

type DocProps = {
  friendly_name: string | undefined;
  approved_date: string | undefined;
  upload_name: string;
  upload_file_type?: string;
};

type LoadedDocProps = {
  ddq_viewing_url?: string;
};

type UserDiscoveryProps = {
  propel_organization_url: string;
  propel_user_url: string;
  organization_name?: string;
};

type DocId = {
  id: string;
};

type DocPropsWithId = DocId & DocProps;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyProps = { [key: string]: any };

type PairId = {
  doc_id: string;
  pair_id: string;
};

type WithDirection = {
  direction: 'prev' | 'next';
};

type WithIndex = {
  index: number;
};

type LoadTime = {
  loadTime: number;
};

type WithError = {
  error: string;
};

type EventDict = {
  loadDdqList: AnyProps;
  loadDdqListSuccess: LoadTime;
  loadDdqListFailure: LoadTime;
  loadDdqCategories: AnyProps;
  loadDdqCategoriesSuccess: LoadTime;
  loadDdqCategoriesFailure: LoadTime;
  patchDoc: DocId & AnyProps;
  patchDocSuccess: DocId & AnyProps;
  patchDocFailure: DocId & AnyProps & WithError;
  deleteDoc: DocPropsWithId;
  deleteDocSuccess: DocId;
  deleteDocFailure: DocPropsWithId & WithError;
  viewAnalysisClick: DocPropsWithId;
  loadingAnalysis: DocId;
  loadingAnalysisSuccess: DocPropsWithId & LoadTime & AnyProps;
  loadingAnalysisFailure: DocId & LoadTime & WithError;
  loadingSearch: AnyProps;
  loadingSearchSuccess: AnyProps & LoadTime;
  loadingSearchFailure: AnyProps & LoadTime & WithError;
  analysisClickQuestionArrow: PairId & WithDirection;
  analysisClickReviewed: PairId;
  analysisClickUndoReview: PairId;
  analysisClickAnswerArrow: PairId & WithDirection & WithIndex;
  analysisSelectAnswerDropdown: PairId & WithIndex;
  analysisNewAnswerSelectedFromDropdown: PairId & WithIndex;
  analysisClickQuestionCard: PairId & WithIndex;
  analysisChangeFilterView: DocId & { new_type: 'all' | 'unreviewed' };
  uploadDoc: {
    status: 'approved' | 'review' | 'bulk';
  } & DocProps &
    UserDiscoveryProps &
    AnyProps;
  uploadDocSuccess: DocPropsWithId & UserDiscoveryProps & LoadedDocProps;
  uploadDocFailure: {
    status: 'approved' | 'review' | 'bulk';
  } & DocProps &
    UserDiscoveryProps &
    WithError;
  generateAnswerSelectSource: DocPropsWithId & {
    pairId: string;
    pairQuestion: string;
    sourceDescription: string;
    sourceRelevancy: 'high' | 'low' | false;
    sourcePairId: string;
    sourcePairQuestion: string;
    sourcePairApprovalDate: string;
    sourcePairFriendlyName: string;
    selected: boolean;
  };
  generateAnswerSubmit: DocPropsWithId & {
    selectedSources: string[];
    instructions: string;
  };
  generateAnswerSuccess: DocPropsWithId &
    LoadTime & {
      selectedSources: string[];
      instructions: string;
      result: string;
    };
  generateAnswerFailure: DocPropsWithId &
    LoadTime & {
      selectedSources: string[];
      instructions: string;
      error: string;
    };
  copy: {
    copyTitle: string;
  }
  autoDraftClick: DocPropsWithId
  categoryClick: {
    categoryName: string;
    expand: boolean;
  }
};

export const captureEvent = <T extends keyof EventDict>(
  event: T,
  properties: EventDict[T]
) => {
  if (!initialized) {
    console.warn(
      'event not captured because not initialized',
      event,
      properties
    );
    return;
  }
  console.log('captureEvent', event, properties);
  try {
    posthog.capture(event, properties);
  } catch (e) {
    console.error(e);
  }
};

export const identify = (id: string, email?: string) => {
  if (!initialized) {
    console.warn('identify not captured because not initialized', id);
    return;
  }
  try {
    posthog.identify(id, email ? { email } : {});
  } catch (e) {
    console.error(e);
  }
};

export const unidentify = () => {
  if (!initialized) {
    console.warn('reset identify not captured because not initialized');
    return;
  }
  try {
    posthog.reset();
  } catch (e) {
    console.error(e);
  }
}

export const runTimerInMs = () => {
  const start = new Date();

  return () => {
    const stop = new Date();

    return stop.getTime() - start.getTime();
  };
};

if (import.meta.env.PROD) {
  posthog.init('phc_qeXaoWNIXEWTW4L3ojJ6wFcCFbdpieR7EBQRwWHFvpe', {
    api_host: 'https://app.posthog.com'
  });

  initialized = true;
} else {
  console.warn('not initializing posthog in dev mode');
}
