//...
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://1b3d66c357599c4e4a39810a65ad7b64@o4507663572992000.ingest.us.sentry.io/4507663582822400',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^http:\/\/127.0.0.1:8080\/api/,
    /^https:\/\/api\.governgpt\.com\/api/
  ],
  // Session Replay
  replaysSessionSampleRate: import.meta.env.PROD ? 1.0 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: import.meta.env.MODE
});

import './lib/analytics.ts';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { RequiredAuthProvider, RedirectToLogin } from '@propelauth/react';
import { Navigate } from 'react-router-dom';

import './globals.css';
import { Loading } from './components/generic/loading.tsx';
import { DDQResultsPage } from './pages/ddq-results.tsx';
import { CopyToken } from './lib/copy-token.tsx';
import { DDQListPage } from './pages/list.tsx';
import { Identify, Reset } from './lib/identify.tsx';

import './register-worker.ts';
import { SyncAuth } from './lib/sync-auth.tsx';
import { queryClientAtom } from 'jotai-tanstack-query';
import { useHydrateAtoms } from 'jotai/react/utils';
import { FunctionComponent, ReactNode } from 'react';
import { WritableAtom } from 'jotai';
import GovernGPTErrorBoundary from './pages/error-boundary.tsx';
import DebugPage from './pages/debug.tsx';
import LockedEditorPage from './pages/locked-editor.tsx';
import { KeyboardManagerProvider } from './lib/keyboard-manager.tsx';


/* eslint-disable-next-line */
const ErrorBoundary = import.meta.env.DEV ? undefined : GovernGPTErrorBoundary;

const router = createBrowserRouter([
  {
    path: '/ddq/list',
    element: <DDQListPage />,
    ErrorBoundary
  },
  {
    path: '/ddq/:uuid/results',
    element: <DDQResultsPage />,
    ErrorBoundary
  },
  {
    path: '/locked-editor',
    element: (
      <LockedEditorPage
        initialValue={
          '<p data-locked="true"><i>Here\'s a question</i></p><p data-locked="true"><i>Testing</i></p><p data-locked="true"><i>Here are some instructions</i></p><table><tr><th data-locked="true">Heading</th></tr><tr><td>Content</td></tr></table>'
        }
      />
    )
  },
  ...(import.meta.env.DEV
    ? [
        {
          path: '/debug',
          element: <DebugPage />
        }
      ]
    : []),
  {
    path: '*',
    element: <Navigate to='/ddq/list' />,
    ErrorBoundary
  }
]);

const queryClient = new QueryClient();

// eslint-disable-next-line react-refresh/only-export-components
function AtomsHydrator({
  atomValues,
  children
}: {
  atomValues: Iterable<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    readonly [WritableAtom<unknown, [any], unknown>, unknown]
  >;
  children: ReactNode;
}) {
  useHydrateAtoms(new Map(atomValues));
  return children as ReturnType<FunctionComponent>;
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <AtomsHydrator atomValues={[[queryClientAtom, queryClient]]}>
    <RequiredAuthProvider
      authUrl={import.meta.env.VITE_APP_AUTH_URL}
      displayWhileLoading={<Loading />}
      displayIfLoggedOut={
        <Reset>
          <RedirectToLogin />
        </Reset>
      }
    >
      <SyncAuth />
      <Identify />
      <CopyToken />
      <QueryClientProvider client={queryClient}>
        <KeyboardManagerProvider>
          <RouterProvider router={router} />
        </KeyboardManagerProvider>
      </QueryClientProvider>
    </RequiredAuthProvider>
  </AtomsHydrator>
);
