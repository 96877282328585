// KeyboardManagerContext.tsx
import { createContext, useEffect, useRef, ReactNode } from 'react';

export interface KeyboardShortcutHandler {
  callback: (event: KeyboardEvent) => boolean | void;
  priority: number;
}

interface KeyboardManagerContextType {
  registerHandler: (handler: KeyboardShortcutHandler) => void;
  unregisterHandler: (handler: KeyboardShortcutHandler) => void;
}

export const KeyboardManagerContext = createContext<
  KeyboardManagerContextType | undefined
>(undefined);

interface KeyboardManagerProviderProps {
  children: ReactNode;
}

export function KeyboardManagerProvider({
  children
}: KeyboardManagerProviderProps) {
  const handlersRef = useRef<KeyboardShortcutHandler[]>([]);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      // Handlers are processed in order of priority (highest first)
      const handlers = handlersRef.current
        .slice()
        .sort((a, b) => b.priority - a.priority);

      console.log('keyboard', event.key, event.altKey)

      let currentPriority = null;
      for (const { callback, priority } of handlers) {
        if (currentPriority !== null && priority !== currentPriority) {
          break;
        }

        const shouldStop = callback(event) || false;
        if (shouldStop) {
          currentPriority = priority;
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const registerHandler = (handler: KeyboardShortcutHandler) => {
    handlersRef.current.push(handler);
  };

  const unregisterHandler = (handler: KeyboardShortcutHandler) => {
    handlersRef.current = handlersRef.current.filter((h) => h !== handler);
  };

  return (
    <KeyboardManagerContext.Provider
      value={{ registerHandler, unregisterHandler }}
    >
      {children}
    </KeyboardManagerContext.Provider>
  );
}
