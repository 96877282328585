import * as React from 'react';
import { QuestionMarkCircledIcon } from '@radix-ui/react-icons';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/components/ui/tooltip';

interface HelpHintProps {
  children: React.ReactNode;
}

const HelpHint: React.FC<HelpHintProps> = ({ children }) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <QuestionMarkCircledIcon className='h-4 w-4 text-muted-foreground inline cursor-help' />
        </TooltipTrigger>
        <TooltipContent className='max-w-sm'>{children}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default HelpHint;
