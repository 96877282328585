/**
 * This code was generated by v0 by Vercel.
 * @see https://v0.dev/t/auZXu2oDsIs
 */
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { DDQRender } from '../components/ddq/components/ddq-render';

import { HomeIcon } from '@radix-ui/react-icons';
import { Separator } from '../components/ui/separator';
import {
  TimedProgress
} from '../components/ui/progress';
import { useBlankStatus } from '@/lib/is-blank';
import { useAnalysisQuery } from '@/queries/analysis';
import { useProgressiveSearch } from '@/components/ddq/progressive-search';
import { useCategoryQuery } from '@/queries/category';
import { useEffect } from 'react';
import { Filter } from '@/components/ddq/components/filter';
import { useFilterState } from '@/components/ddq/use-filter-state';
import SearchBar from '@/components/search/use-search';

export function DDQResultsPage() {
  const { uuid: ddqId } = useParams<{ uuid: string }>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  const navigate = useNavigate();

  const { isBlank } = useBlankStatus();

  const { process } = useProgressiveSearch();

  const { analysisQuery } = useAnalysisQuery({ ddqId: ddqId || '', isBlank, process });

  const { isLoading, isError } = analysisQuery;

  const { categoryQuery } = useCategoryQuery();

  const { setSelectedCategory } = useFilterState();

  useEffect(() => {
    if (analysisQuery.data) {
      const defaultCategory = categoryQuery.data?.find(category => category.id === analysisQuery.data.category_id)?.id 
        || categoryQuery.data?.find(category => category.name === 'Firm')?.id || 'none';
      setSelectedCategory(defaultCategory);
    }
  }, [analysisQuery.data, categoryQuery.data, setSelectedCategory]);

  return (
    <div className='h-screen w-screen flex flex-col'>
      <div className='flex text-gray-500 items-center gap-2 p-2 text-sm cursor-default'>
        <div className='flex flex-shrink-0 gap-2'>
          <HomeIcon
            onClick={() => navigate('/')}
            className='mt-1 cursor-pointer'
          />
          /
          <span
            onClick={() => navigate('/ddq/list')}
            className='cursor-pointer'
          >
            DDQ List
          </span>
          {analysisQuery.data && (
            <span>
              /{' '}
              {analysisQuery.data.friendly_name ||
                analysisQuery.data.upload_name.substring(0, 80) + '...'}
            </span>
          )}
        </div>
        <div className='flex flex-grow justify-center text-black'>
          <SearchBar
            searchLabel='Search the database with a different phrase...'
            inDDQContext
          />
        </div>
        <div className='text-black'>
          <Filter
            isLoading={isLoading}
            categoryData={categoryQuery.data || []}
          />
        </div>
      </div>
      <Separator />
      {analysisQuery.data && (
        <DDQRender analysisResponse={analysisQuery.data} />
      )}
      {isLoading && (
        <div className='flex-grow w-screen justify-center flex flex-col items-center px-8'>
          <span className='text-base text-gray-600 mb-4'>
            Finding past answers for each question in this questionnaire...
          </span>
          <TimedProgress durationMs={10 * 1000} />
        </div>
      )}
      {isError && <Navigate to='/' />}
    </div>
  );
}
