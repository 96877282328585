import type { Editor as TiptapEditor } from '@tiptap/core';
import { useEditor, EditorContent, ReactNodeViewRenderer } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { Link } from '@tiptap/extension-link';
import { Image } from '@tiptap/extension-image';
import { Table } from '@tiptap/extension-table';
import { TableCell } from '@tiptap/extension-table-cell';
import { TableHeader } from '@tiptap/extension-table-header';
import { TableRow } from '@tiptap/extension-table-row';
import { Separator } from '@/components/ui/separator';
import { cn } from '@/lib/utils';
import SectionOne from './section-1';
import SectionTwo from './section-2';
import SectionThree from './section-3';
import SectionFour from './section-4';
import { ImageViewBlock } from './image/image-view-block';
import { LinkBubbleMenu } from './bubble-menu/link-bubble-menu';
import { getOutput } from '../utils';
import { ImageBubbleMenu } from './bubble-menu/image-bubble-menu';
import { forwardRef, useImperativeHandle } from 'react';
import SectionFive from './section-5';
import SectionSix from './section-6';

export interface MinimalTiptapProps
  extends React.HTMLAttributes<HTMLDivElement> {
  value?: string | null;
  outputValue?: 'html' | 'json' | 'text';
  disabled?: boolean;
  contentClass?: string;
  onValueChange: (value: string) => void;
}

export type RerenderEditorContentHandle = {
  rerender: (value: string) => void;
  focus: () => void;
  blur: () => void;
  isFocused: () => boolean;
};

const MinimalTiptapEditor = forwardRef<
  RerenderEditorContentHandle,
  MinimalTiptapProps
>(
  (
    {
      value,
      outputValue = 'html',
      disabled,
      contentClass,
      onValueChange,
      className,
      ...props
    }: MinimalTiptapProps,
    ref
  ) => {
    const editor = useEditor({
      extensions: [
        StarterKit,
        Image.extend({
          addNodeView() {
            return ReactNodeViewRenderer(ImageViewBlock);
          }
        }),
        Link.configure({
          openOnClick: false
        }),
        Table.configure({
          resizable: true
        }),
        TableRow.configure({
          HTMLAttributes: {
            class: 'border prose-p:my-0 prose-p:mx-2'
          }
        }),
        TableHeader.configure({
          HTMLAttributes: {
            class: 'border prose-p:my-0 prose-p:mx-1'
          }
        }),
        TableCell.configure({
          HTMLAttributes: {
            class: 'border prose-p:my-0 prose-p:mx-1'
          }
        })
      ],
      editorProps: {
        attributes: {
          class: 'prose mx-auto focus:outline-none max-w-none'
        }
      },
      onUpdate: (props) => {
        onValueChange(getOutput(props.editor, outputValue));
      },
      content: value,
      editable: !disabled,
      onCreate: ({ editor }) => {
        if (value) {
          editor.chain().setContent(value).run();
        }
      }
    });

    useImperativeHandle(ref, () => {
      return {
        rerender(value: string) {
          editor
            ?.chain()
            .setContent(value || '')
            .run();
          console.log('rerender', value);
        },
        focus () {
          editor?.chain().focus().run();
        },
        blur () {
          editor?.chain().blur().run();
        },
        isFocused () {
          return !!editor?.isFocused
        }
      };
    });

    return (
      <div
        className={cn(
          'flex h-auto min-h-72 w-full flex-col rounded-md border border-input shadow-sm focus-within:border-primary',
          className
        )}
        {...props}
      >
        {editor && (
          <>
            <LinkBubbleMenu editor={editor} />
            <ImageBubbleMenu editor={editor} />
            <Toolbar editor={editor} />
          </>
        )}
        <div
          className='h-full grow overflow-y-scroll'
          onClick={() => editor?.chain().focus().run()}
        >
          <EditorContent editor={editor} className={cn('p-5', contentClass)} />
        </div>
      </div>
    );
  }
);

MinimalTiptapEditor.displayName = 'MinimalTiptapEditor';

const Toolbar = ({ editor }: { editor: TiptapEditor }) => {
  return (
    <div className='border-b border-border p-2'>
      <div className='flex w-full flex-wrap items-center'>
        <SectionOne editor={editor} />
        <Separator orientation='vertical' className='mx-2 h-7' />
        <SectionTwo editor={editor} />
        <Separator orientation='vertical' className='mx-2 h-7' />
        <SectionThree editor={editor} />
        <Separator orientation='vertical' className='mx-2 h-7' />
        <SectionFour editor={editor} />
        <Separator orientation='vertical' className='mx-2 h-7' />
        <SectionFive editor={editor} />
        <Separator orientation='vertical' className='mx-2 h-7' />
        <SectionSix editor={editor} />
      </div>
    </div>
  );
};

export { MinimalTiptapEditor };
