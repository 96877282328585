import { Button } from '@/components/ui/button';
import { Logo } from '@/components/logo';
import {
  useAuthInfo,
  useLogoutFunction,
  useRedirectFunctions
} from '@propelauth/react';
import { useNavigate } from 'react-router-dom';
import { unidentify } from '@/lib/analytics';
import { useCallback } from 'react';

export const Header: React.ComponentType<
  React.PropsWithChildren<{ className?: string }>
> = (props) => {
  const { user } = useAuthInfo();
  const { redirectToAccountPage } = useRedirectFunctions();
  const logoutFunction = useLogoutFunction();

  const logout = useCallback(() => {
    unidentify();
    logoutFunction(true);
  }, [logoutFunction])

  const navigate = useNavigate();

  return (
    <header
      className={`flex justify-between items-center p-4 bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700 ${props.className}`}
    >
      <div onClick={() => navigate('/')} className='cursor-pointer'>
        <Logo />
      </div>
      {props.children}
      {user && (
        <div className='flex items-center space-x-4'>
          <div className='text-sm text-gray-500 dark:text-gray-400'>
            <p>{user.email}</p>
          </div>
          <Button
            onClick={redirectToAccountPage}
            className='mr-2'
            variant='outline'
          >
            Account Settings
          </Button>
          <Button onClick={logout} variant='outline'>
            Logout
          </Button>
        </div>
      )}
    </header>
  );
};
