/**
 * This code was generated by v0 by Vercel.
 * @see https://v0.dev/t/RWmUIeZnF6t
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Button, ButtonLoading } from '@/components/ui/button';
import {
  TableHead,
  TableRow,
  TableHeader,
  TableCell,
  TableBody,
  Table
} from '@/components/ui/table';
import { Container } from '../components/container';
import React, {
  ComponentType,
  KeyboardEventHandler,
  useCallback,
  useRef,
  useState,
  useEffect
} from 'react';
import { DDQ } from '@/types';
import { TimedProgress } from '../components/ui/progress';
import { useAuthInfo } from '@propelauth/react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from '../components/ui/card';
import { Label } from '../components/ui/label';
import { Input } from '../components/ui/input';
import { RadioGroup, RadioGroupItem } from '../components/ui/radio-group';
import { captureEvent, runTimerInMs } from '@/lib/analytics';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue
} from '../components/ui/select';
import {
  FileMinusIcon,
  StopwatchIcon,
  CheckCircledIcon,
  CrossCircledIcon
} from '@radix-ui/react-icons';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '../components/ui/accordion';
import { useCategoryQuery } from '@/queries/category';
import { Loader2, MenuIcon } from 'lucide-react';
import { DropdownMenu } from '../components/ui/dropdown-menu';
import { DropdownMenuTrigger } from '../components/ui/dropdown-menu';
import { DropdownMenuContent } from '../components/ui/dropdown-menu';
import { DropdownMenuLabel } from '../components/ui/dropdown-menu';
import { DropdownMenuSeparator } from '../components/ui/dropdown-menu';
import { DropdownMenuItem } from '../components/ui/dropdown-menu';
import { useUserData } from '@/lib/user-data';
import { useIsInternal } from '@/lib/is-internal';
import SearchBar from '@/components/search/use-search';
import Cookies from 'js-cookie';
import HelpHint from '@/components/info/help-hint';

const mimeTypeMapping: { [key: string]: string } = {
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'docx',
  'application/pdf': 'pdf'
};

const DDQSubmit: React.ComponentType<{ onSubmit?: () => void }> = ({
  onSubmit
}) => {
  const [ddqStatus, setDdqStatus] = React.useState<'approved' | 'review'>(
    'approved'
  );
  const [friendlyName, setFriendlyName] = React.useState('');
  const [approvedDate, setApprovedDate] = React.useState('');
  const [category, setCategory] = React.useState<string>();
  const [file, setFile] = React.useState<File | null>(null);

  const fileRef = React.useRef<HTMLInputElement>(null);

  const { accessToken } = useAuthInfo();

  const queryClient = useQueryClient();

  const { categoryQuery } = useCategoryQuery();

  const userData = useUserData();

  const addMutation = useMutation<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any,
    {
      ddqStatus: typeof ddqStatus;
      friendlyName: typeof friendlyName;
      approvedDate: typeof approvedDate;
      file: typeof file;
      category: typeof category;
    }
  >({
    mutationFn: async ({
      ddqStatus,
      friendlyName,
      approvedDate,
      file,
      category
    }) => {
      const formData = new FormData();
      if (friendlyName) formData.append('friendly_name', friendlyName);
      if (ddqStatus === 'approved')
        formData.append('approved_date', approvedDate);
      formData.append('file', file as File);
      if (category) formData.append('category_id', category as string);

      captureEvent('uploadDoc', {
        status: ddqStatus,
        friendly_name: friendlyName,
        approved_date: approvedDate,
        upload_name: file?.name || '',
        upload_file_type: mimeTypeMapping[file?.type || ''] || 'unknown',
        category_id: category || null,
        ...userData.inEventFormat
      });

      if (onSubmit) onSubmit();

      const response = await fetch(`${import.meta.env.VITE_API_HOST}/extract`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        body: formData
      });

      const json = await response.json();

      captureEvent('uploadDocSuccess', {
        id: json.id,
        friendly_name: friendlyName,
        approved_date: approvedDate,
        upload_name: file?.name || '',
        upload_file_type: mimeTypeMapping[file?.type || ''] || 'unknown',
        ddq_viewing_url: `https://app.governgpt.com/ddq/${json.id}/results`,
        ...userData.inEventFormat
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['ddqs'] });

      setFriendlyName('');
      setApprovedDate('');
      setFile(null);
      (fileRef.current as HTMLInputElement).value = '';
    },
    onError: (error, variables) => {
      captureEvent('uploadDocFailure', {
        status: variables.ddqStatus,
        friendly_name: variables.friendlyName,
        approved_date: variables.approvedDate,
        upload_name: variables.file?.name || '',
        upload_file_type:
          mimeTypeMapping[variables.file?.type || ''] || 'unknown',
        error: error,
        ...userData.inEventFormat
      });
    }
  });

  return (
    <Card className='border p-1 rounded-lg relative mx-auto w-full md:max-w-md'>
      <CardHeader>
        <CardTitle>Upload a DDQ</CardTitle>
        <CardDescription>Docx processes quickly<br/>- Excels and PDFs slower.</CardDescription>
      </CardHeader>
      <CardContent className='grid gap-4 px-4'>
        <div className='space-b-2'>
          <Label htmlFor='status'>Status</Label>
          <RadioGroup
            className='flex items-center gap-2'
            id='status'
            value={ddqStatus}
            disabled={addMutation.isPending}
            onValueChange={(value) => setDdqStatus(value as typeof ddqStatus)}
          >
            <Label
              className='border cursor-pointer rounded-md p-2 flex items-center gap-2 [&:has(:checked)]:bg-gray-100 dark:[&:has(:checked)]:bg-gray-800'
              htmlFor='status-approved'
            >
              <RadioGroupItem id='status-approved' value='approved' />
              Approved
            </Label>
            <Label
              className='border cursor-pointer rounded-md p-2 flex items-center gap-2 [&:has(:checked)]:bg-gray-100 dark:[&:has(:checked)]:bg-gray-800'
              htmlFor='status-review'
            >
              <RadioGroupItem id='status-review' value='review' />
              Draft
            </Label>
          </RadioGroup>
        </div>
        <div className='space-y-2'>
          <Label htmlFor='friendly-name'>
            Enter a short, recognizable name
          </Label>
          <Input
            disabled={addMutation.isPending}
            id='friendly-name'
            placeholder='Friendly Name'
            value={friendlyName}
            onInput={(e) => setFriendlyName(e.currentTarget.value)}
          />
        </div>
        <div className='space-y-2'>
          <Label>Product</Label>
          <Select
            value={category}
            onValueChange={setCategory}
            disabled={addMutation.isPending}
          >
            <SelectTrigger>
              <SelectValue placeholder='Select a product' />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Products</SelectLabel>
                {(categoryQuery?.data || []).map((category) => (
                  <SelectItem key={category.id} value={category.id}>
                    {category.name}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        {ddqStatus === 'approved' && (
          <div className='space-y-2'>
            <Label htmlFor='approved-date'>Approved Date</Label>
            <Input
              disabled={addMutation.isPending}
              id='approved-date'
              type='date'
              value={approvedDate}
              onInput={(event) => setApprovedDate(event.currentTarget.value)}
            />
          </div>
        )}
        <div className='space-y-2'>
          <Label htmlFor='file-picker'>Upload a file*</Label>
          <Input
            ref={fileRef}
            disabled={addMutation.isPending}
            required
            id='file-picker'
            className='cursor-pointer'
            type='file'
            accept='application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            onChange={(event) => {
              console.log(event.target?.files?.[0]);
              setFile(event.target?.files?.[0] || null);
            }}
          />
        </div>
      </CardContent>
      <CardFooter className='flex justify-center'>
        {!addMutation.isPending && (
          <Button
            disabled={addMutation.isPending}
            className='w-full'
            onClick={() => {
              addMutation.mutate({
                ddqStatus,
                friendlyName,
                approvedDate,
                file,
                category
              });
            }}
          >
            Submit
          </Button>
        )}
        {addMutation.isPending && (
          <ButtonLoading className='w-full'>Upload</ButtonLoading>
        )}
      </CardFooter>
    </Card>
  );
};

const DDQMultiSubmit: React.ComponentType<{ onSubmit?: () => void }> = ({
  onSubmit
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [uploadResults, setUploadResults] = useState<boolean[]>([]);

  const queryClient = useQueryClient();

  const fileRef = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState(false);

  const { accessToken } = useAuthInfo();

  const userData = useUserData();

  const submitFiles = useCallback(async () => {
    if (loading) return;

    setLoading(true);
    setUploadResults([]);

    const localResults: boolean[] = [];

    if (files.length > 0 && onSubmit) onSubmit();

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      try {
        // submit
        const formData = new FormData();
        formData.append('file', file as File);
        formData.append('guess_date', 'true');

        captureEvent('uploadDoc', {
          status: 'bulk',
          friendly_name: undefined,
          approved_date: undefined,
          upload_name: file?.name || '',
          upload_file_type: mimeTypeMapping[file?.type || ''] || 'unknown',
          ...userData.inEventFormat
        });

        const response = await fetch(
          `${import.meta.env.VITE_API_HOST}/extract`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${accessToken}`
            },
            body: formData
          }
        );

        const data = await response.json();

        captureEvent('uploadDocSuccess', {
          id: data.id,
          friendly_name: undefined,
          approved_date: undefined,
          upload_name: file?.name || '',
          upload_file_type: mimeTypeMapping[file?.type || ''] || 'unknown',
          ddq_viewing_url: `https://app.governgpt.com/ddq/${data.id}/results`,
          ...userData.inEventFormat
        });

        localResults.push(true);
        setUploadResults([...localResults]);

        // reset the list query
        queryClient.invalidateQueries({ queryKey: ['ddqs'] });
      } catch (e) {
        captureEvent('uploadDocFailure', {
          status: 'bulk',
          friendly_name: undefined,
          approved_date: undefined,
          upload_name: file?.name || '',
          upload_file_type: mimeTypeMapping[file?.type || ''] || 'unknown',
          error: String(e),
          ...userData.inEventFormat
        });

        // leave it in the list
        localResults.push(false);
        setUploadResults([...localResults]);
      }
    }

    setLoading(false);

    // keep only the failed files
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setFiles(files.filter((value, index) => !localResults[index]));
    setUploadResults([]);
  }, [
    files,
    setFiles,
    loading,
    setLoading,
    accessToken,
    setUploadResults,
    queryClient,
    userData.inEventFormat,
    onSubmit
  ]);

  return (
    <Card className='border p-1 mt-4 rounded-lg relative mx-auto w-full md:max-w-md'>
      <CardHeader>
        <CardTitle>Bulk Import</CardTitle>
        <CardDescription>
          Upload an entire directory of Words to process quickly.
          <br/>
          Excels and PDFs also supported (slower).
          <br />
          GovernGPT can guess approval date, name, and product category.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className='space-y-2'>
          <Label htmlFor='multi-file-picker'>Upload files*</Label>
          <Input
            ref={fileRef}
            disabled={loading}
            required
            id='multi-file-picker'
            className='cursor-pointer'
            type='file'
            accept='application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            multiple
            onChange={(event) => {
              const filesToAdd = event.target.files
                ? Array.from(event.target.files)
                : [];
              setFiles([...filesToAdd]);
              setUploadResults([]);
            }}
          />
        </div>
        <div className='space-y-2 max-w-full overflow-x-hidden mt-2'>
          {(files || []).map((file, index) => (
            <div
              key={index}
              className='flex justify-between items-center whitespace-nowrap max-w-full my-0.5 pl-2 border rounded-sm'
            >
              <span
                title={file.name}
                className={
                  'max-w-full overflow-x-hidden text-ellipsis text-sm text-gray-700'
                }
              >
                {file.name}
              </span>
              {loading ? (
                <>
                  {/** not yet started */}
                  {uploadResults.length < index && (
                    <Button
                      disabled
                      className='ml-2 text-white bg-gray-600 rounded px-2.5 py-0'
                    >
                      <StopwatchIcon />
                    </Button>
                  )}
                  {/** uploading */}
                  {uploadResults.length === index && (
                    <ButtonLoading className='ml-2 text-white bg-gray-600 rounded px-2.5 py-0'></ButtonLoading>
                  )}
                  {/** succeeded */}
                  {uploadResults.length > index && uploadResults[index] && (
                    <Button
                      disabled
                      className='ml-2 text-white bg-green-600 rounded px-2.5 py-0'
                    >
                      <CheckCircledIcon />
                    </Button>
                  )}
                  {/** failed */}
                  {uploadResults.length > index && !uploadResults[index] && (
                    <Button
                      disabled
                      className='ml-2 text-white bg-red-600 rounded px-2.5 py-0'
                    >
                      <CrossCircledIcon />
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  className='ml-2 text-white bg-red-500 rounded px-2 py-0'
                  onClick={() => {
                    setFiles([
                      ...files.slice(0, index),
                      ...files.slice(index + 1, files.length)
                    ]);
                  }}
                  title='Remove from upload.'
                >
                  <FileMinusIcon height='20' width='20' />
                </Button>
              )}
            </div>
          ))}
        </div>
      </CardContent>
      <CardFooter className='flex justify-center'>
        {!loading && (
          <Button
            disabled={files.length === 0}
            className='w-full'
            onClick={submitFiles}
          >
            Submit
          </Button>
        )}
        {loading && <ButtonLoading className='w-full'>Upload</ButtonLoading>}
      </CardFooter>
    </Card>
  );
};

const DDQRow: React.ComponentType<{ ddq: DDQ }> = ({ ddq }) => {
  const [editMode, setEditMode] = React.useState<
    | {
        friendly_name?: string;
        approved_date?: string | null;
        category_id?: string;
      }
    | undefined
  >(undefined);
  const nameRef = useRef<HTMLInputElement>(null);
  const dateRef = useRef<HTMLInputElement>(null);
  const categoryTriggerRef = useRef<HTMLButtonElement>(null);
  const categoryContentRef = useRef<HTMLDivElement>(null);
  const undoButtonRef = useRef<HTMLButtonElement>(null);

  const { accessToken } = useAuthInfo();
  const { isInternalUser } = useIsInternal();

  const navigate = useNavigate();

  const onClickAnalysis = (blank = false) => {
    captureEvent('viewAnalysisClick', {
      id: ddq.id,
      friendly_name: ddq.friendly_name,
      approved_date: ddq.approved_date,
      upload_name: ddq.upload_name
    });

    navigate(`/ddq/${ddq.id}/results${blank ? '?blank=true' : ''}`);
  };

  const queryClient = useQueryClient();

  const deleteMutation = useMutation({
    mutationFn: async () => {
      captureEvent('deleteDoc', {
        id: ddq.id,
        friendly_name: ddq.friendly_name,
        approved_date: ddq.approved_date,
        upload_name: ddq.upload_name
      });

      await fetch(`${import.meta.env.VITE_API_HOST}/${ddq.id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
    },
    onSuccess: () => {
      captureEvent('deleteDocSuccess', { id: ddq.id });

      queryClient.invalidateQueries({ queryKey: ['ddqs'] });
    },
    onError: (error) => {
      captureEvent('deleteDocFailure', {
        id: ddq.id,
        friendly_name: ddq.friendly_name,
        approved_date: ddq.approved_date,
        upload_name: ddq.upload_name,
        error: String(error)
      });
    }
  });

  const modifyMutation = useMutation<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any,
    {
      friendly_name?: string;
      approved_date?: string | null;
      category_id?: string;
    }
  >({
    mutationFn: async ({ friendly_name, approved_date, category_id }) => {
      const submitVars: {
        friendly_name?: string;
        approved_date?: string | -1;
        category_id?: string;
      } = {};

      if (friendly_name !== undefined) {
        submitVars['friendly_name'] = friendly_name;
      }

      if (approved_date !== undefined) {
        submitVars['approved_date'] =
          typeof approved_date === 'string' ? approved_date : -1;
      }

      if (category_id !== undefined) {
        submitVars['category_id'] = category_id;
      }

      captureEvent('patchDoc', {
        id: ddq.id,
        friendly_name,
        approved_date,
        category_id
      });

      await fetch(`${import.meta.env.VITE_API_HOST}/${ddq.id}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(submitVars)
      });
    },
    onSuccess: async (_data, variables) => {
      captureEvent('patchDocSuccess', {
        id: ddq.id,
        ...variables
      });

      await queryClient.invalidateQueries({ queryKey: ['ddqs'] });

      setTimeout(() => setEditMode(undefined), 0);
    },
    onError: (error, variables) => {
      captureEvent('patchDocFailure', {
        id: ddq.id,
        ...variables,
        error: String(error)
      });
    }
  });

  const { categoryQuery } = useCategoryQuery();

  const getCategoryName = (uuid?: string) => {
    if (!uuid) return 'Unknown';

    const category = categoryQuery.data?.find((c) => c.id === uuid);
    return category?.name || 'Unknown';
  };

  const onLoseFocus = () => {
    if (modifyMutation.isPending) return;

    if (
      document.activeElement === nameRef.current ||
      document.activeElement === dateRef.current ||
      document.activeElement === categoryTriggerRef.current ||
      document.activeElement === categoryContentRef.current ||
      categoryContentRef.current?.contains(document.activeElement) ||
      document.activeElement === undoButtonRef.current
    )
      return;

    // unfocused and nothing is changed
    if (
      editMode?.approved_date === undefined &&
      !editMode?.friendly_name &&
      !editMode?.category_id
    ) {
      setEditMode(undefined);
      return;
    }

    modifyMutation.mutate(editMode);
  };

  const onKeyDown: KeyboardEventHandler<HTMLElement> = (event) => {
    if (event.key === 'Enter') {
      nameRef.current?.blur();
      dateRef.current?.blur();
      categoryContentRef.current?.blur();
      categoryTriggerRef.current?.blur();

      return;
    }

    if (event.key === 'Escape') {
      setEditMode(undefined);
      return;
    }
  };

  const [reprocessEnabled, setReprocessEnabled] = useState(true);

  const onDoubleClickStatus = async () => {
    if (reprocessEnabled === false) return;
    setReprocessEnabled(false);

    try {
      await fetch(`${import.meta.env.VITE_API_HOST}/${ddq.id}/reprocess`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        method: 'POST'
      });
    } catch (e) {
      setReprocessEnabled(true);
    }
  };

  const downloadFileMutation = useMutation<
    { url: string },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any,
    { ddqId: string }
  >({
    mutationFn: async ({ ddqId }) => {
      try {
        const response = await fetch(
          `${import.meta.env.VITE_API_HOST}/${ddqId}/uploaded-file`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            },
            method: 'GET'
          }
        );
        if (!response.ok) {
          throw new Error('Failed to fetch download URL');
        }
        const data = await response.json();
        return { url: data.url };
      } catch (error) {
        console.error('Error fetching download URL:', error);
        throw error;
      }
    },
    onSuccess: ({ url }) => {
      window.open(url, '_blank');
    },
    onError: (error) => {
      console.error('Error during file download:', error);
    }
  });

  const approveMutation = useMutation({
    mutationFn: async () => {
      const response = await fetch(`${import.meta.env.VITE_API_HOST}/ddq/${ddq.id}/approve`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to approve DDQ');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['ddqs'] });
    },
    onError: (error) => {
      console.error('Error approving DDQ:', error);
    }
  });

  const unapproveMutation = useMutation({
    mutationFn: async () => {
      const response = await fetch(`${import.meta.env.VITE_API_HOST}/ddq/${ddq.id}/unapprove`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to unapprove DDQ');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['ddqs'] });
    },
    onError: (error) => {
      console.error('Error unapproving DDQ:', error);
    }
  });

  return (
    <TableRow className='col-span-4' key={ddq.id}>
      <TableCell>
        {reprocessEnabled === false && (
          <div
            onDoubleClick={onDoubleClickStatus}
            title='This DDQ is being reprocessed at your request.'
            className='w-2 h-2 bg-gray-400 rounded-full'
          ></div>
        )}
        {reprocessEnabled === true && ddq.status === 'processed' && (
          <div
            onDoubleClick={onDoubleClickStatus}
            title='This DDQ is processed and searchable.'
            className='w-2 h-2 bg-green-400 rounded-full'
          ></div>
        )}
        {reprocessEnabled === true && ddq.status === 'uploaded' && (
          <div
            onDoubleClick={onDoubleClickStatus}
            title='This DDQ is being processed.'
            className='w-2 h-2 bg-yellow-400 rounded-full'
          ></div>
        )}
        {reprocessEnabled === true && ddq.status === 'failed' && (
          <div
            onDoubleClick={onDoubleClickStatus}
            title='We encountered an error processing this DDQ.'
            className='w-2 h-2 bg-red-400 rounded-full'
          ></div>
        )}
      </TableCell>
      <TableCell
        className={!editMode ? 'cursor-pointer' : ''}
        onDoubleClick={() => {
          setEditMode(editMode ? undefined : {});
          setTimeout(() => nameRef.current?.focus(), 0);
        }}
        title='Double-click to edit'
      >
        {editMode ? (
          <Input
            ref={nameRef}
            disabled={modifyMutation.isPending}
            id='friendly-name'
            placeholder='Friendly Name'
            value={
              editMode?.friendly_name === undefined
                ? ddq.friendly_name
                : editMode?.friendly_name
            }
            onInput={(e) =>
              setEditMode({ ...editMode, friendly_name: e.currentTarget.value })
            }
            onBlur={() => setTimeout(onLoseFocus, 0)}
            onKeyDown={onKeyDown}
          />
        ) : (
          ddq.friendly_name
        )}
      </TableCell>
      <TableCell
        className={!editMode ? 'cursor-pointer' : ''}
        onDoubleClick={() => {
          setEditMode(editMode ? undefined : {});
          setTimeout(() => dateRef.current?.focus(), 0);
        }}
        title='Double-click to edit'
      >
        {editMode ? (
          <div className='flex'>
            <Input
              ref={dateRef}
              disabled={modifyMutation.isPending}
              id='approved-date'
              type='date'
              value={
                editMode?.approved_date === undefined
                  ? typeof ddq.approved_date === 'string'
                    ? ddq.approved_date.substring(0, 10)
                    : ''
                  : editMode?.approved_date || ''
              }
              onInput={(e) =>
                setEditMode({
                  ...editMode,
                  approved_date: e.currentTarget.value
                })
              }
              onBlur={() => setTimeout(onLoseFocus, 0)}
              onKeyDown={onKeyDown}
            />
            <Button
              ref={undoButtonRef}
              className='ml-2'
              variant='outline'
              disabled={modifyMutation.isPending}
              onClick={() => {
                setEditMode({ ...editMode, approved_date: null });
                modifyMutation.mutate({ ...editMode, approved_date: null });
              }}
            >
              Undo approval
            </Button>
          </div>
        ) : (
          ddq.approved_date && dayjs(ddq.approved_date).format('MMM D, YYYY')
        )}
      </TableCell>
      <TableCell
        className={!editMode ? 'cursor-pointer' : ''}
        onDoubleClick={() => {
          setEditMode(editMode ? undefined : {});
          setTimeout(() => categoryTriggerRef.current?.focus(), 0);
        }}
        title='Double-click to edit'
      >
        {editMode ? (
          <Select
            value={
              (editMode?.category_id === undefined
                ? ddq.category_id
                : editMode?.category_id) || undefined
            }
            onValueChange={(value) =>
              setEditMode({
                ...editMode,
                category_id: value
              })
            }
          >
            <SelectTrigger
              onKeyDown={onKeyDown}
              onBlur={() => {
                setTimeout(onLoseFocus, 0);
              }}
              ref={categoryTriggerRef}
            >
              <SelectValue placeholder='Select a product' />
            </SelectTrigger>
            <SelectContent
              onBlur={() => {
                setTimeout(onLoseFocus, 0);
              }}
              ref={categoryContentRef}
              onKeyDown={onKeyDown}
            >
              <SelectGroup>
                <SelectLabel>Products</SelectLabel>
                {(categoryQuery?.data || []).map((category) => (
                  <SelectItem key={category.id} value={category.id}>
                    {category.name}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        ) : (
          getCategoryName(ddq.category_id)
        )}
      </TableCell>
      <TableCell className='flex-grow'>
        {ddq.upload_name.split('.').slice(0, -1).join('.')}
      </TableCell>
      <TableCell>
        <div className='flex space-x-2'>
          {ddq.status !== 'processed' ? (
            <Button variant='outline' disabled>
              The AI is Processing...
            </Button>
          ) : ddq.approved_date ? (
            <Button variant='outline' onClick={() => onClickAnalysis(true)}>
              View Past Answers
            </Button>
          ) : (
            <Button onClick={() => onClickAnalysis()}>
              Fill this Questionnaire
            </Button>
          )}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant='outline'>
                <MenuIcon />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuLabel>DDQ Options</DropdownMenuLabel>
              {isInternalUser && (
                <DropdownMenuItem
                  className={'cursor-pointer'}
                  onClick={() => onClickAnalysis(true)}
                >
                  View Document (No Search)
                </DropdownMenuItem>
              )}
              {isInternalUser && (
                <DropdownMenuItem
                  className={'cursor-pointer'}
                  onClick={() => {
                    downloadFileMutation.mutate({ ddqId: ddq.id });
                  }}
                >
                  {downloadFileMutation.isPending && (
                    <Loader2 className={'w-4 h-4 animate-spin mr-2'} />
                  )}{' '}
                  Download Original
                </DropdownMenuItem>
              )}
              {isInternalUser && ddq.status === 'uploaded' && (
                <DropdownMenuItem
                  className='cursor-pointer'
                  onClick={() => approveMutation.mutate()}
                  disabled={approveMutation.isPending}
                >
                  {approveMutation.isPending && (
                    <Loader2 className='w-4 h-4 animate-spin mr-2' />
                  )}
                  Approve DDQ
                </DropdownMenuItem>
              )}
              {isInternalUser && ddq.status === 'processed' && (
                <DropdownMenuItem
                  className='cursor-pointer'
                  onClick={() => unapproveMutation.mutate()}
                  disabled={unapproveMutation.isPending}
                >
                  {unapproveMutation.isPending && (
                    <Loader2 className='w-4 h-4 animate-spin mr-2' />
                  )}
                  Unapprove DDQ
                </DropdownMenuItem>
              )}
              <DropdownMenuSeparator />
              <DropdownMenuItem
                className='cursor-pointer'
                onClick={(event) => {
                  deleteMutation.mutate();
                  // So that menu stays open and loading state is preserved.
                  event.preventDefault();
                }}
                disabled={deleteMutation.isPending}
              >
                {deleteMutation.isPending && (
                  <Loader2 className={'w-4 h-4 animate-spin mr-2'} />
                )}{' '}
                Delete Permanently
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </TableCell>
    </TableRow>
  );
};

export function DDQListPage() {
  const { accessToken } = useAuthInfo();

  const ddqQuery = useQuery<DDQ[]>({
    queryKey: ['ddqs'],
    queryFn: async () => {
      const timer = runTimerInMs();

      captureEvent('loadDdqList', {});

      try {
        const response = await fetch(`${import.meta.env.VITE_API_HOST}/list`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        const data = await response.json();

        const elapsed = timer();

        captureEvent('loadDdqListSuccess', {
          loadTime: elapsed
        });

        return data;
      } catch (error) {
        const elapsed = timer();

        captureEvent('loadDdqListFailure', {
          loadTime: elapsed
        });

        throw error;
      }
    }
  });

  const { categoryQuery } = useCategoryQuery();

  const lessThan24HoursElapsed = (dateString: string) =>
    dayjs().unix() - dayjs(dateString).unix() < 24 * 60 * 60;

  // drafts have not been approved and are not in search results
  const ddqDrafts = (ddqQuery.data || []).filter(
    (ddq) => ddq.status === 'processed' && ddq.approved_date === null
  );
  const ddqNotDrafts = (ddqQuery.data || []).filter(
    (ddq) => ddq.status === 'processed' && ddq.approved_date !== null
  );
  // processing docs, failed docs, or uploaded within 24 hours are in uploads
  const ddqUploads = (ddqQuery.data || []).filter(
    (ddq) =>
      ddq.status === 'uploaded' ||
      ddq.status === 'failed' ||
      (ddq.status === 'processed' && lessThan24HoursElapsed(ddq.created))
  );
  // per category breakdown, including none
  const ddqApprovedPerCategory: { [key: string]: DDQ[] } = ddqNotDrafts.reduce(
    (categoryMap, ddq) => {
      const categoryId = ddq.category_id || 'uncategorized';

      if (categoryMap[categoryId] === undefined) {
        categoryMap[categoryId] = [];
      }

      categoryMap[categoryId].push(ddq);

      return categoryMap;
    },
    {} as { [key: string]: DDQ[] }
  );

  const getDefaultAccordionValue = () => {
    const cookieValue = Cookies.get('ddqAccordion');
    return cookieValue ? cookieValue : 'drafts';
  };

  const [ddqAccordion, setDDQAccordion] = useState<string>(getDefaultAccordionValue());

  useEffect(() => {
    Cookies.set('ddqAccordion', ddqAccordion || 'drafts');
  }, [ddqAccordion]);

  return (
    <Container headerChildren={<SearchBar />}>
      <div className='grid grid-cols-5 space-x-4'>
        <div className='col-span-1'>
          <DDQSubmit onSubmit={() => setDDQAccordion('uploads')} />
          <DDQMultiSubmit onSubmit={() => setDDQAccordion('uploads')} />
        </div>
        <div className='col-span-4'>
          <Accordion
            type='single'
            collapsible
            className='w-full'
            defaultValue={getDefaultAccordionValue()}
            value={ddqAccordion}
            onValueChange={(value) => {
              setDDQAccordion(value);
              captureEvent('categoryClick', {
                categoryName: value || '',
                expand: !!value
              });
            }}
          >
            <AccordionItem value='drafts'>
              <AccordionTrigger>
                <span>
                  Drafts ({ddqDrafts.length}){' '}
                  <span className='text-gray-400 italic font-light'>
                    - hidden from past answers until an approval date is set by
                    double clicking
                  </span>
                </span>
              </AccordionTrigger>
              <AccordionContent>
                <DDQTable ddqs={ddqDrafts} loading={ddqQuery.isLoading} />
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value='uploads'>
              <AccordionTrigger>
                <span>
                  Recently Uploaded &amp; Uploading ({ddqUploads.length}){' '}
                  <span className='text-gray-400 italic font-light'>
                    - processing or recently processed
                  </span>
                </span>
              </AccordionTrigger>
              <AccordionContent>
                <DDQTable ddqs={ddqUploads} loading={ddqQuery.isLoading} />
              </AccordionContent>
            </AccordionItem>
            {(categoryQuery.data || []).map((category) => (
              <AccordionItem value={category.id} key={category.id}>
                <AccordionTrigger>
                  <span>
                    <span className={'text-gray-400 italic font-light'}>
                      Product
                    </span>{' '}
                    {category.name} (
                    {(ddqApprovedPerCategory[category.id] || []).length}{' '}
                    Approved){' '}
                    <span className='text-gray-400 italic font-light'>
                      - shown in search results because they have approval dates
                    </span>
                  </span>
                </AccordionTrigger>
                <AccordionContent>
                  <DDQTable
                    ddqs={ddqApprovedPerCategory[category.id] || []}
                    loading={ddqQuery.isLoading}
                  />
                </AccordionContent>
              </AccordionItem>
            ))}
            <AccordionItem value='uncategorized'>
              <AccordionTrigger>
                <span>
                  <span className={'text-gray-400 italic font-light'}>
                    Product
                  </span>{' '}
                  Uncategorized (
                  {(ddqApprovedPerCategory['uncategorized'] || []).length}{' '}
                  Approved){' '}
                  <span className='text-gray-400 italic font-light'>
                    - shown in search results because they have approval dates
                  </span>
                </span>
              </AccordionTrigger>
              <AccordionContent>
                <DDQTable
                  ddqs={ddqApprovedPerCategory['uncategorized'] || []}
                  loading={ddqQuery.isLoading}
                />
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </Container>
  );
}

const DDQTable: ComponentType<{ ddqs: DDQ[]; loading: boolean }> = ({
  ddqs,
  loading
}) => {
  return (
    <Table className='relative'>
      <TableHeader className='sticky top-0'>
        <TableRow>
          <TableHead></TableHead>
          <TableHead>
            Friendly Name&nbsp;
            <HelpHint>
              <p>Double-click the field to set a "friendly name" which appears throughout GovernGPT as a quick-to-understand reference for this file</p>
            </HelpHint>
          </TableHead>
          <TableHead>
            Approved Date&nbsp;
            <HelpHint>
              <p>Double-click the field to set an approval date or unapprove a document to show/hide it from search</p>
            </HelpHint>
          </TableHead>
          <TableHead>
            Product&nbsp;
            <HelpHint>
              <p>Double-click the field to set a product category, which will help you understand where this content comes from when you see it in search results</p>
            </HelpHint>
          </TableHead>
          <TableHead className='flex-grow'>Uploaded File</TableHead>
          <TableHead>Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {loading && (
          <TableRow>
            <TableCell colSpan={6}>
              <TimedProgress durationMs={3000} />
            </TableCell>
          </TableRow>
        )}
        {(ddqs || []).map((ddq) => (
          <DDQRow key={ddq.id} ddq={ddq} />
        ))}
      </TableBody>
    </Table>
  );
};
