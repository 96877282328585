import { useAuthInfo } from '@propelauth/react';
import { identify, unidentify } from './analytics';

export function Identify() {
    const { user, impersonatorUserId, isImpersonating } = useAuthInfo();

    const id = isImpersonating ? impersonatorUserId : user?.userId;
    const email = isImpersonating ? 'impersonator@governgpt.com' : user?.email;

    if (id) identify(id, email);

    return <></>;
}

export function Reset({ children }: { children?: React.ReactNode }) {
    unidentify();
    return <>{children}</>;
}
