import type { Editor } from '@tiptap/core'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger, DropdownMenuSub, DropdownMenuSubContent, DropdownMenuSubTrigger, DropdownMenuPortal } from '@/components/ui/dropdown-menu'
import { CaretDownIcon, TableIcon } from '@radix-ui/react-icons'
import { ToolbarButton } from './toolbar-button'

export default function SectionFive({ editor }: { editor: Editor }) {
  return (
    <>
      {/* TABLE CONTROLS */}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <ToolbarButton
            tooltip='Table controls'
            aria-label='Table controls'
            className='w-12'
          >
            <TableIcon className='size-5' />
            <CaretDownIcon className='size-5' />
          </ToolbarButton>
        </DropdownMenuTrigger>
        <DropdownMenuContent align='start' className='w-full'>
          <DropdownMenuItem
            onClick={() => editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()}
          >
            Insert table
          </DropdownMenuItem>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>Modify rows</DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                <DropdownMenuItem
                  onClick={() => editor.chain().focus().addRowBefore().run()}
                  disabled={!editor.isActive('table')}
                >
                  Add row before
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => editor.chain().focus().addRowAfter().run()}
                  disabled={!editor.isActive('table')}
                >
                  Add row after
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => editor.chain().focus().deleteRow().run()}
                  disabled={!editor.isActive('table')}
                >
                  Delete row
                </DropdownMenuItem>
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>Modify columns</DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                <DropdownMenuItem
                  onClick={() => editor.chain().focus().addColumnBefore().run()}
                  disabled={!editor.isActive('table')}
                >
                  Add column before
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => editor.chain().focus().addColumnAfter().run()}
                  disabled={!editor.isActive('table')}
                >
                  Add column after
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => editor.chain().focus().deleteColumn().run()}
                  disabled={!editor.isActive('table')}
                >
                  Delete column
                </DropdownMenuItem>
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>Modify cells</DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                <DropdownMenuItem
                  onClick={() => editor.chain().focus().mergeCells().run()}
                  disabled={!editor.isActive('table')}
                >
                  Merge cells
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => editor.chain().focus().splitCell().run()}
                  disabled={!editor.isActive('table')}
                >
                  Split cell
                </DropdownMenuItem>
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>Modify header</DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                <DropdownMenuItem
                  onClick={() => editor.chain().focus().toggleHeaderColumn().run()}
                  disabled={!editor.isActive('table')}
                >
                  Toggle header column
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => editor.chain().focus().toggleHeaderRow().run()}
                  disabled={!editor.isActive('table')}
                >
                  Toggle header row
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => editor.chain().focus().toggleHeaderCell().run()}
                  disabled={!editor.isActive('table')}
                >
                  Toggle header cell
                </DropdownMenuItem>
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
          <DropdownMenuItem
            onClick={() => editor.chain().focus().deleteTable().run()}
            disabled={!editor.isActive('table')}
          >
            Delete table
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
