import { Switch } from '@/components/ui/switch';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useFilterState } from '@/components/ddq/use-filter-state';
import { Category } from '@/types';
import { ShortcutKey } from '@/components/minimal-tiptap/components/shortcut-key';
import { ignoreWhenFocused, useKeyboardShortcut } from '@/lib/use-keyboard-shortcut';

interface FilterProps {
  isLoading: boolean;
  categoryData: Category[];
}

export function Filter({ isLoading, categoryData }: FilterProps) {
  const {
    filterOnOff,
    setFilterOnOff,
    selectedCategory,
    setSelectedCategory,
    selectedContentRange,
    setSelectedContentRange
  } = useFilterState();

  const getCurrentYear = () => new Date().getFullYear();
  const getLastYear = () => getCurrentYear() - 1;
  const getLastQuarter = () => {
    const currentMonth = new Date().getMonth();
    const currentQuarter = Math.floor(currentMonth / 3);
    const lastQuarter = currentQuarter === 0 ? 3 : currentQuarter - 1;
    const lastQuarterYear = currentQuarter === 0 ? getLastYear() : getCurrentYear();
    return `Q${lastQuarter + 1} ${lastQuarterYear}`;
  };

  const handleFilterShortcut = ignoreWhenFocused((event: KeyboardEvent) => {
    if (event.key === 'f') {
      setFilterOnOff(!filterOnOff);
      console.log('Filter is now', !filterOnOff ? 'enabled' : 'disabled');

      return true;
    }
  });

  useKeyboardShortcut(handleFilterShortcut, 10);

  return (
    <div className='flex justify-center items-center gap-4 text-sm'>
      <Switch
        checked={filterOnOff}
        onCheckedChange={(checked: boolean) => {
          setFilterOnOff(checked);
          console.log('Switch is now', checked ? 'enabled' : 'disabled');
        }}
        disabled={isLoading}
      />
      <ShortcutKey className='-ml-3' keys={['f']} />
      <span className='text-gray-500'>Show only</span>
      <Select
        value={selectedCategory || 'none'}
        onValueChange={(value) => {
          setSelectedCategory(value);
          console.log('Selected category:', value);
        }}
        disabled={isLoading || !filterOnOff}
      >
        <SelectTrigger className='w-48'>
          <SelectValue placeholder='Select a category' />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value='none'>All Categories</SelectItem>
          {categoryData.map((category) => (
            <SelectItem key={category.id} value={category.id}>
              {category.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <span className='text-gray-500'>content since</span>
      <Select
        value={selectedContentRange || ''}
        onValueChange={(value) => {
          setSelectedContentRange(value);
          console.log('Selected time range:', value);
        }}
        disabled={isLoading || !filterOnOff}
      >
        <SelectTrigger className='w-48'>
          <SelectValue placeholder='Select time range' />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value='lastQuarter'>Last quarter {getLastQuarter()}</SelectItem>
          <SelectItem value='thisYear'>This year ({getCurrentYear()})</SelectItem>
          <SelectItem value='lastYear'>Last year ({getLastYear()})</SelectItem>
          <SelectItem value='forever'>Forever (no limit)</SelectItem>
        </SelectContent>
      </Select>
    </div>
  );
}
